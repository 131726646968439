function customRule(choice: number, choicesLength: number) {
  const num = Math.abs(choice) % 100;
  const lastDigit = num % 10;

  if (num > 10 && num < 20) {
    return Math.min(2, choicesLength - 1);
  } else if (lastDigit > 1 && lastDigit < 5) {
    return 1;
  } else if (lastDigit === 1) {
    return 0;
  }

  if (choicesLength === 4 && num === 0) return 3;

  return Math.min(2, choicesLength - 1);
}

export default defineI18nConfig(() => ({
  legacy: false,
  pluralRules: {
    ru: customRule,
    en: customRule,
    es: customRule,
    pt: customRule,
    tr: customRule,
    dev: customRule,
  },
  defaultLocale: 'en',
  fallbackLocale: 'en',
  fallbackWarn: false,
  missingWarn: false,
}));
